.container {
  background: #c8d7e0;
  padding: 0px !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  font-family: Arial;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.imgRow {
  background: #c8d7e0;
  padding: 10px;
  border-top: 1px solid #5381ac;
}

.imgCol {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.img {
  max-height: 50px;
  max-width: 90%;
}
