.root {
  height: 100vh;
  position: fixed;
  z-index: 100;
  left: 0px;
  top: 0px;
  background: #5381ac;
  width: 70px;
  transition: 0.2s all ease-in-out;
  @media (max-width: 699px) {
    width: 100vw;
    height: 78px;
  }
}
.openRoot {
  @media (min-width: 700px) {
    width: 235px;
  }
  @media (max-width: 699px) {
    height: 100vh;
  }
}

.container {
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-between;
  @media (min-width: 700px) {
    // height: 172px;
    flex-direction: column;
  }
  @media (max-width: 699px) {
    position: fixed;
    left: 0px;
    top: 0px;
  }
}

.logo {
  width: 52px;
  height: 52px;
  margin: 20px 8px 0px 8px;
  @media (max-width: 699px) {
    width: 40px;
    height: 40px;
    margin: 20px 24px 20px 24px;
  }
}

.textLogo {
  height: 52px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  margin: 20px 0px 0px 8px;
  @media (max-width: 699px) {
    display: none;
  }
}

.textLogoOpen {
  opacity: 1;
}

.current {
  text-align: center;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 699px) {
    display: none;
  }
}

.iconButton {
  margin: 0px 16px 13px 16px;
  font-size: 30px;
  width: 38px;
  text-align: center;
  height: 38px;
  color: #5381ac;
  cursor: pointer;
  @media (max-width: 699px) {
    margin: 20px 20px;
  }
}

.closeTextButton {
  font-size: 20px;
  line-height: 30px;
  font-family: Arial;
  font-weight: bold;
  transition: 0.2s opacity ease-in-out;
  color: #5381ac;
  @media (max-width: 699px) {
    display: none;
  }
}

.menuItem {
  display: flex;
  align-items: center;
  min-height: 40px;
}
.menuItemTitle {
  font-size: 20px;
  width: 100%;
  font-family: Arial;
  font-weight: bold;
  transition: 0.2s opacity ease-in-out;
  color: white;
}

.activeMenuItem {
  background: rgba(255, 255, 255, 0.2);
}

.openLink {
  color: white;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
}

.iconLink {
  text-decoration: none;
}

.innerIcon {
  width: 38px;
  text-align: center;
  font-size: 30px;
  height: 38px;
  color: #ffffff;
  margin: 12px 16px 12px 16px;
  @media (max-width: 699px) {
    margin: 24px 24px;
  }
}

.svg {
  width: 30px;
  text-align: center;
  padding: 4px 4px 0px 4px;
  font-size: 28px;
  height: 28px;
  color: #ffffff;
  margin: 12px 16px 12px 16px;
  @media (max-width: 699px) {
    margin: 24px 24px;
  }
}

.innerMenuRoot {
  @media (max-width: 699px) {
    height: calc(100% - 114px);
  }
  width: 100%;
  margin-top: 0px;
  height: calc(100% - 208px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.innerMenuRootClosed {
  @media (max-width: 699px) {
    display: none;
    height: calc(100% - 172px);
  }
}

.innerMenuContainer {
  display: flex;
  flex: 2.5;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: flex-start;

  &:first-child {
    flex: 2;
    border-bottom: 1px solid white;
  }
}

.select {
  @media (max-width: 699px) {
    margin-top: 78px;
  }
}
