.button {
  height: 38px;
  max-height: 76px;
  background-color: rgb(83, 129, 172);
  color: white;
  border: none;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
  padding: 0px 16px;
}
