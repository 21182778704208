body {
  margin: 0;
  padding: 0;
  background: rgb(200, 215, 224);
  overflow-x: hidden !important;
  font-family: sans-serif;
}

.hover-card:hover {
  border-bottom: 0 none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
}

.faq-hover:hover {
  background-color: rgba(200, 215, 224, 0.3);
  border-left: 4px solid #5381ac;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes move-up {
  from {
    bottom: 30px;
  }
  to {
    bottom: 42px;
  }
}

/* Style checker 
* {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
}
*/

.spinner {
  width: 40px;
  height: 40px;
  background-color: rgb(83, 129, 172);

  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.Select-menu-outer {
  z-index: 999 !important;
}
