.pm-launch-infobox {
  z-index: 999999;
  background: white;
  position: relative;
  margin-left: 71px;
  padding: 10px 5px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .pm-launch-infobox {
    display: none;
  }
}
