.button {
  width: 90%;
  max-width: 350px;
  height: 38px;
  margin-top: 32px;
  font-size: 14px;
  background-color: #5381ac;
  color: white;
  font-weight: bold;
  border: solid 1px #cccccc;
}

.input {
  width: 90%;
  color: black;
  max-width: 340px;
  font-size: 14px;
  height: 38px;
  background-color: #ffffff;
  border: solid 1px #cccccc;
  margin-bottom: 16px;
  padding-left: 10px;
}

.preferenceBar {
  width: 90%;
  max-width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ffffff;
}

.resetLink {
  color: white;
  text-decoration: none;
}

.form {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  font-size: 24px;
  color: white;
  font-weight: bold;
  margin-bottom: 35px;
  @media (max-width: 499px) {
    font-size: 16px;
  }
}

.version {
  text-align: center;
  font-size: 16px;
  color: white;
}

.logo {
  width: 80%;
  max-width: 333px;
  margin: auto;
  padding-top: 63px;
  display: block;
  @media (max-width: 499px) {
    max-width: 235px;
    padding-top: 37px;
  }
}

.container {
  width: 100vw;
  font-family: Arial;
  &:before {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    z-index: -1;
    background-image: url('../../../images/background1.jpg');
    background-size: cover;
    mix-blend-mode: multiply;
    width: 100vw;
    height: 100vh;
    display: block;
    filter: blur(5px);
  }
}
