/* CHECKMARK */

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  margin: 0px 5px;
  margin-top: 8px;
  font-weight: bold;
  line-height: 20px;
  color: white;
}

.checkbox-container--labeled {
  position: static;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
}

.checkbox-todo {
  color: #5381ac;
  margin-top: 0px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  border: 1px solid white;
  background-color: #eee;
  left: 0;
  height: 20px;
  width: 20px;
}

.checkmark--labeled {
  position: relative;
  margin-left: 10px;
}

.checkmark-modal {
  top: 0;
}

.checkmark-todo {
  top: -10px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: rgba(200, 215, 224, 0.2);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.checkbox-todo .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #5381ac;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
