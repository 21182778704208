.datepicker {
  height: 36px;
  font-size: 14px;
  padding-left: 5px;
}

.datepicker.invalid {
  border: 1px solid red;
}

.react-datepicker__day--selected {
  background-color: #5381ac;
}

.react-datepicker__header {
  background-color: rgb(200, 215, 224);
}

.react-datepicker__month-container {
  /*border-radius: "none";*/
}
.react-datepicker__day--keyboard-selected {
  background-color: rgb(200, 215, 224);
}

.react-datepicker__navigation--next {
  border-left-color: #5381ac;
}

.react-datepicker__navigation--previous {
  border-right-color: #5381ac;
}
.react-datepicker__day--keyboard-selected:hover {
  background-color: #99b3c3;
}

input.milestone-datepicker {
  margin: 10px;
  height: 32px;
  font-size: 14px;
}

input.todo-datepicker {
  height: 32px;
}
