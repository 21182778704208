.pulse {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 2px;
  cursor: pointer;
}

.pulse:hover {
  animation: none;
}

.pulse0 {
  background: rgb(155, 155, 155);
  box-shadow: 0 0 0 rgba(155, 155, 155, 0.4);
  animation: pulse0 2s infinite;
}

.pulse1 {
  background: rgb(62, 138, 27);
  box-shadow: 0 0 0 rgba(62, 138, 27, 0.4);
  animation: pulse1 2s infinite;
}

.pulse2 {
  background: rgb(50, 156, 226);
  box-shadow: 0 0 0 rgba(50, 156, 226, 0.4);
  animation: pulse2 2s infinite;
}

.pulse3 {
  background: rgb(194, 156, 31);
  box-shadow: 0 0 0 rgba(194, 156, 31, 0.4);
  animation: pulse3 2s infinite;
}

.pulse4 {
  background: rgb(153, 14, 33);
  box-shadow: 0 0 0 rgba(153, 14, 33, 0.4);
  animation: pulse4 2s infinite;
}

@keyframes pulse0 {
  0% {
    box-shadow: 0 0 0 0 rgba(155, 155, 155, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(155, 155, 155, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(155, 155, 155, 0);
  }
}

@keyframes pulse1 {
  0% {
    box-shadow: 0 0 0 0 rgba(62, 138, 27, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(62, 138, 27, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(62, 138, 27, 0);
  }
}

@keyframes pulse2 {
  0% {
    box-shadow: 0 0 0 0 rgba(50, 156, 226, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(50, 156, 226, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(50, 156, 226, 0);
  }
}

@keyframes pulse3 {
  0% {
    box-shadow: 0 0 0 0 rgba(194, 156, 31, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(194, 156, 31, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(194, 156, 31, 0);
  }
}

@keyframes pulse4 {
  0% {
    box-shadow: 0 0 0 0 rgba(153, 14, 33, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(153, 14, 33, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(153, 14, 33, 0);
  }
}
