.Toastify__toast--error {
  background-color: white;
  color: rgb(83, 129, 172);
}

.Toastify__toast--success {
  background-color: white;
  color: rgb(83, 129, 172);
}

.Toastify__toast-body {
  display: flex;
}

.Toastify__progress-bar {
  background: rgb(83, 129, 172);
}

.Toastify__close-button {
  color: rgb(83, 129, 172);
}

.Toastify__toast {
  border-radius: 4px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.175);
  display: flex;
  margin-bottom: 8px;
}
