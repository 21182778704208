.Popover-tip {
    fill: white;
  }
  
  .Popover {
    z-index: 1000;
  }

  .popover {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    bottom: 42px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    display: none;
    font-size: 12px;
    font-family: "Helvetica", sans-serif;
    left: -95px;
    padding: 7px 10px;
    position: absolute;
    width: 200px;
    z-index: 4;
  }
  .popover:before {
    border-top: 7px solid rgba(0, 0, 0, 0.85);
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    bottom: -7px;
    content: "";
    display: block;
    left: 50%;
    margin-left: -7px;
    position: absolute;
  }
  .popover {
    display: block;
    animation: fade-in 0.3s linear 1, move-up 0.3s linear 1;
  }